import assert from 'assert'
import { Grid } from '@mui/material'
import { styled } from '@mui/system'
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { StaticImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import React from 'react'
import { Layout } from '../components/Layout'
import { ActionButton } from '../components/Ring/ActionButton'
import { Guide } from '../components/Ring/Guide'
import { SEO } from '../components/SEO'
import { Txt } from '../components/Txt'
import type { MaterialHeroImageQuery } from '../../types/graphql-types'
import type { PageProps } from 'gatsby'

const ITEMS = [
    {
        title: 'プラチナ950',
        description:
            'ブライダルリングの地金として最も多く流通されるプラチナ950は、プラチナ95％、パラジウム3％、ルテニウム2％で構成される、貴金属としての価値と強度を両立した高級マテリアル。',
        image: (
            <StaticImage
                src="../images/material/material-1.jpg"
                alt=""
                width={160}
            />
        ),
    },
    {
        title: 'プラチナ100',
        description:
            'シルバー90％、プラチナ10％で構成される言わば高級シルバー。プラチナを10％含有させることで、従来シルバーのデメリットであった変色を起きにくくしたカジュアルマテリアル。',
        image: (
            <StaticImage
                src="../images/material/material-2.jpg"
                alt=""
                width={160}
            />
        ),
    },
    {
        title: 'K18イエロー・ピンクゴールド',
        description:
            'ゴールドジュエリーで最もポピュラーなK18はゴールド75％にシルバー、亜鉛、銅などを含有し鮮やかな色合いが特徴。貴金属としての価値と強度に加え、経年変色も起きにくい材質。',
        image: (
            <StaticImage
                src="../images/material/material-3.jpg"
                alt=""
                width={160}
            />
        ),
    },
    {
        title: 'K5イエロー・ピンクゴールド',
        description:
            'ゴールド含有率21％にシルバー、亜鉛、銅で構成された金種。K18と比較すると変色は起きやすいものの、鮮やかな色合いと高いコストパフォーマンスで使用頻度が少ない方や気軽に着けたい方に人気。',
        image: (
            <StaticImage
                src="../images/material/material-4.jpg"
                alt=""
                width={160}
            />
        ),
    },
    {
        title: '変色について',
        description: (
            <>
                PT100やK5ゴールド製品はプラチナ950やK18ゴールドに比べ経年による変色はやや起きやすい金属のため、リングのメンテナンスも都度承っております。
                <br />
                傷や経年による若干の変色、照りの落ち具合が年季を感じて使いやすいともいわれますが、気になる方は新品仕上げがおすすめです。（1回2,200円）
            </>
        ),
        primary: true,
    },
    {
        title: '金属アレルギーについて',
        description:
            '金属によるアレルギー反応は金種によって異なり、個人差が大きいため一概にはご案内ができない問題です。普段から金属アレルギー反応のある方、アレルギーが心配な方には専門の医療機関等での事前検査をお勧めしております。',
        primary: true,
    },
]

export const Head = () => (
    <SEO
        title="リングの地金に使用するプラチナ、ゴールド、その他材質についてのご案内"
        description="MissPlatinumのリング地金は幅広くお選び頂けます。価値と強度を両立したプラチナ950、ジュエリーで最も人気のK18ゴールド、リーズナブルな価格を実現し、変色の問題をクリアしたプラチナ100など、お客様のお好みに合わせて製作を承っております。"
        page="material"
    />
)

const MaterialPage: React.FC<PageProps<MaterialHeroImageQuery>> = ({
    data,
}) => {
    const imageData = data.file?.childImageSharp?.gatsbyImageData
    assert(imageData)
    const bgImage = convertToBgImage(imageData)

    return (
        <Layout breadcrumbs={[{ name: '材質について' }]}>
            <Grid container spacing={0}>
                <Grid item sm={12} xs={12} component="main">
                    <Hero Tag="section" {...bgImage}>
                        <HeroTextWrapper>
                            <H1>
                                <Txt size="xl">材質について</Txt>
                            </H1>
                        </HeroTextWrapper>
                    </Hero>
                    <Grid container spacing={0}>
                        {ITEMS.map((item, index) => (
                            <Grid item sm={6} xs={12} key={index}>
                                <Wrapper>
                                    <Title>
                                        {item.primary ? (
                                            <Txt primary>{item.title}</Txt>
                                        ) : (
                                            <Txt>{item.title}</Txt>
                                        )}
                                    </Title>
                                    <BodyWrapper>
                                        {item.image && (
                                            <Image>{item.image}</Image>
                                        )}
                                        <Description>
                                            {item.description}
                                        </Description>
                                    </BodyWrapper>
                                </Wrapper>
                            </Grid>
                        ))}
                    </Grid>
                    <ActionButton />
                    <Guide />
                </Grid>
            </Grid>
        </Layout>
    )
}

export const query = graphql`
    query MaterialHeroImage {
        file(relativePath: { eq: "material/hero.jpg" }) {
            childImageSharp {
                gatsbyImageData(formats: [WEBP])
            }
        }
    }
`

const Hero = styled(BackgroundImage)({
    height: '235px',
    position: 'relative',
    width: '100%',
})
const HeroTextWrapper = styled('div')({
    position: 'absolute',
    top: '178px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    textShadow: '0 0 4px #333333',
    color: '#ffffff',
})
const H1 = styled('h1')({
    margin: 0,
})
const Wrapper = styled('section')({
    display: 'flex',
    flexDirection: 'column',
    padding: '8px',
    marginTop: '12px',
})
const Title = styled('h2')({
    margin: '0 0 4px',
})
const BodyWrapper = styled('div')({
    display: 'flex',
})
const Image = styled('div')({
    marginRight: '8px',
})
const Description = styled('p')({
    flex: 1,
    margin: 0,
    fontSize: '13px',
})

export default MaterialPage
